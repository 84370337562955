<template lang="pug">
  v-tooltip(bottom color="tertiary" max-width="300")
    template(v-slot:activator="{ on, attrs }")
      span( v-bind="attrs" v-on="on")
        slot(name="content")
          v-btn(
            color="primary",
            small,
            rounded
          )
            v-icon mdi-book
    span.black--text
      slot(name="tooltip-content") Tooltip Content
</template>
<script>
export default {
  name: "TooltipBtn",
};
</script>
