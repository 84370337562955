<template lang="pug">
v-card(tile flat :width="imgWidth" :height="imgHeight" color="#333f48").book
  v-img.float1(
    :src="url",
    lazy-src="@/assets/cover.png" 
    :max-width="imgWidth",
    :height="imgHeight",
    style="object-fit: cover"
    )
    //- img(src="@/assets/cover.png")
    template(v-slot:placeholder)
      v-row(
        class="fill-height ma-0"
        align="center"
        justify="center"
        )
          v-progress-circular(
          indeterminate
          color="grey lighten-5")
</template>
<script>
export default {
  name: "Image",
  props: { url: String, imgWidth: String, imgHeight: String },
};
</script>
<style scoped>
.float1 {
  box-shadow: 0 1em 1em -0.4em gray;
}

.book {
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  perspective: 1200px;
}

.float1:hover {
  -webkit-transform: rotateY(-30deg) !important;
  -moz-transform: rotateY(-30deg) !important;
  transform: rotateY(-30deg) !important;
  -webkit-transform-origin: 0;
  -moz-transform-origin: 0;
  transform-origin: 0;
}
</style>
