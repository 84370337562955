<template lang="pug">
div(style="min-height: 90vh")
  v-dialog(v-model="caution", width="600")
    v-card
      v-card-title.headline.grey.lighten-2 Shelf is Full
      .pa-5
        p.font-weight-light The maximum capacity for your shelf is only <strong>50 books</strong>, try to remove some books.
      v-divider
      v-card-actions.px-5.pb-5
        v-spacer
        v-btn(color="grey", text, @click="caution = false").mr-2 Close
        v-btn(:to="{name: 'Shelf'}" color="teal" dark) Go to Shelf
  v-overlay(opacity=".9" color="#f3f3f3" v-if="searchClient==null")
    v-progress-circular.ml-lg-15(
      :size="70"
      :width="7"
      color="primary"
      indeterminate)
  ais-instant-search(
    :search-client="searchClient",
    index-name="books",
    :routing="routing"
    :stalled-search-delay="200"
  )
    ais-pagination
      div(slot-scope="{refine}")
        v-row(justify="center").mb-n8
          v-col(cols="12" sm="12" md="9")
            v-text-field(
              @focus="refine(0); focused=true"
              @blur="focused=false"
              filled
              :rounded="!focused"
              :solo="focused"
              clear-icon="mdi-close-circle"
              prepend-inner-icon="mdi-magnify",
              dense
              v-model.trim="search",
              clearable,
              placeholder="Search"
              @keyup.enter="searching()"
            ).mx-2.mx-md-0.mt-md-5
              template(v-slot:append)
                v-btn(
                v-if="focused"
                color="primary"
                small
                depressed
                rounded
                ).mr-n2 Search
                v-btn(
                v-else
                small
                depressed
                disabled
                rounded
                style="margin-top: -2.5px"
                ).mr-n2 Search
          v-col(md="2" cols="4").text-center.mt-n8.ml-auto.mr-2.mt-md-0.ml-md-0.mr-md-0
            div.caption Books per page
            v-select(v-model="hitsPerPage" type="number" dense :items=[10, 20, 30] rounded filled)
    div.ml-md-15.ml-3.d-flex.align-center
      ais-stats.mr-3
        .body-2.font-weight-light(slot-scope="{nbHits, processingTimeMS}" ) <strong>{{ nbHits }}</strong> results retrieved <span v-if="search.length>3"> for <strong>"{{ search }}"</strong> </span>
    ais-configure(
      :query="search",
      :hitsPerPage = "hitsPerPage"
    )
    div.mt-n3.ml-2.mb-4
      ais-current-refinements
        div(slot="item", slot-scope="{ item, refine}")
          v-chip.mt-1.mb-1.text-capitalize.mx-1(
            v-for="refinement in item.refinements",
            :key="refinement.value",
            small,
            close,
            @click:close="refine(refinement)"
          ) <span class="font-weight-light mr-1"> {{ item.label }}: </span> {{ refinement.label }}
      //- ais-clear-refinements.mt-2.ml-1
      //-   span(slot-scope="{ canRefine, refine }")
      //-     v-btn(@click="refine()" v-if="canRefine" color="white" x-small).mr-3 Clear filters
   
    //- Filter for Mobile
    v-dialog(v-model="drawer" 
      hide-overlay
      transition="dialog-bottom-transition" fullscreen v-if="$vuetify.breakpoint.smAndDown")
      v-card(tile)
        v-toolbar(
          dark
          flat
          color="primary")
          v-btn(
            icon
            dark
            @click="drawer = false")
            v-icon mdi-close
          v-toolbar-title Filters
          v-spacer
          v-toolbar-items
            v-btn(
              dark
              text
              @click="drawer = false") Save
        div.px-5.pt-2
          h6.mt-4 Year
          ais-range-input(attribute="year")
            v-chip(slot="submitLabel", small, color="primary") Go
            span.font-weight-light.body-2(slot="separator") to
          h6.mt-4 Subject
          ais-refinement-list(attribute="subject" :limit="15")
            div(slot-scope="{ items, refine}")
              div(v-for="item in items", :key="item.value")
                .d-flex.align-start.mb-2.black--text
                  v-icon(
                    @click.prevent="refine(item.value)",
                    dense,
                    :value="true",
                    v-if="!item.isRefined",
                    color="primary"
                  ) mdi-circle-outline
                  v-icon(
                    @click.prevent="refine(item.value)",
                    dense,
                    :value="true",
                    v-if="item.isRefined",
                    color="primary"
                  ) mdi-check-circle
                  a.ml-2.body-2(
                    :style="{ fontWeight: item.isRefined ? '400' : '300' }"
                  ) {{ item.label }} <v-chip x-small label> {{ item.count.toLocaleString() }} </v-chip>
          h6.mt-4 Syllabi
          ais-refinement-list(attribute="institution")
            div(slot-scope="{ items, refine}")
              div(v-for="item in items", :key="item.value")
                .d-flex.align-start.mb-2
                  v-icon(
                    @click.prevent="refine(item.value)",
                    dense,
                    :value="true",
                    v-if="!item.isRefined",
                    color="primary"
                  ) mdi-circle-outline
                  v-icon(
                    @click.prevent="refine(item.value)",
                    dense,
                    :value="true",
                    v-if="item.isRefined",
                    color="primary"
                  ) mdi-check-circle
                  a.ml-2.body-2(
                    :style="{ fontWeight: item.isRefined ? '400' : '300' }"
                  ) {{ item.label }} <v-chip x-small label> {{ item.count.toLocaleString() }} </v-chip>
    //-  Filter
    v-row
      v-col.pr-7.mt-n3(md="3" v-if="$vuetify.breakpoint.mdAndUp")
        v-divider
        h6.font-weight-regular.mb-n1.text-center Advanced Search
        v-divider
        h6.mt-4 Year
        ais-range-input(attribute="year")
          v-chip(slot="submitLabel", small, color="primary") Go
          span.font-weight-light.body-2(slot="separator") to
        ais-refinement-list(attribute="subject" :limit="15")
          div(slot-scope="{ items, refine}")
            h6.mt-4 Subject
            div(v-if="items.length==0").font-weight-light No appropriate filter
            div(v-for="item in items", :key="item.value")
              .d-flex.align-start.mb-2
                v-icon(
                  @click.prevent="refine(item.value)",
                  dense,
                  :value="true",
                  v-if="!item.isRefined",
                  color="primary"
                ) mdi-circle-outline
                v-icon(
                  @click.prevent="refine(item.value)",
                  dense,
                  :value="true",
                  v-if="item.isRefined",
                  color="primary"
                ) mdi-check-circle
                div.ml-2.body-2.black--text(
                  :style="{ fontWeight: item.isRefined ? '400' : '300' }"
                ) {{ item.label }} <v-chip x-small label> {{ item.count.toLocaleString() }} </v-chip>
       
        ais-refinement-list(attribute="institution")
          div(slot-scope="{ items, refine}")
            h6.mt-4 Syllabi
            div(v-if="items.length==0").font-weight-light No appropriate filter
            div(v-for="item in items", :key="item.value")
              .d-flex.align-start.mb-2
                v-icon(
                  @click.prevent="refine(item.value)",
                  dense,
                  :value="true",
                  v-if="!item.isRefined",
                  color="primary"
                ) mdi-circle-outline
                v-icon(
                  @click.prevent="refine(item.value)",
                  dense,
                  :value="true",
                  v-if="item.isRefined",
                  color="primary"
                ) mdi-check-circle
                div.ml-2.body-2.black--text(
                  :style="{ fontWeight: item.isRefined ? '400' : '300' }"
                ) {{ item.label }} <v-chip x-small label> {{ item.count.toLocaleString() }} </v-chip>
      //-  Search Results
      v-col(md="9", cols="12")
        //-  
        ais-hits
          div(slot-scope="{ items }")
            v-btn(@click="drawer = !drawer" v-if="$vuetify.breakpoint.smAndDown && items.length!=0" small rounded color="white").ml-3.mb-3 <v-icon small> mdi-filter </v-icon> <strong>Advanced Search</strong>
            div(v-for="item in items", :key="item.objectID" v-if="items.length!=0")
              v-card.pa-2(tile, outlined, style="text-decoration: none;").mx-2.mx-md-0
                v-row.py-5
                  //- Book Image
                  v-col(lg="2" sm="3", cols="4")
                    bookCover(:url="item.imageUrl" imgHeight="160" imgWidth="110").ml-lg-auto.ml-auto.mx-sm-auto.mx-lg-0

                  //- Book 
                  v-col(lg="7" sm="6", cols="8")
                    div.ml-n2.ml-lg-0
                      span(v-for="s in item.subject")
                          v-chip.mb-2.font-weight-light.mr-2(small, label v-if="s === 'Physics'" color="#b00202" dark) {{ s }}
                          v-chip.mb-2.font-weight-light.mr-2(small, label v-if="s === 'Mathematics'" color="#8c0067" dark) {{ s }}
                          v-chip.mb-2.font-weight-light.mr-2(small, label v-if="s === 'Chemistry'" color="#490163" dark) {{ s }}
                          v-chip.mb-2.font-weight-light.mr-2(small, label v-if="s === 'Biology'" color="#005918" dark) {{ s }}
                          v-chip.mb-2.font-weight-light.mr-2(small, label v-if="s === 'Material Science'" color="#495753" dark) {{ s }}
                          v-chip.mb-2.font-weight-light.mr-2(small, label v-if="s === 'Molecular Biology & Biotechnology'" color="#006e50" dark) {{ s }}
                          v-chip.mb-2.font-weight-light.mr-2(small, label v-if="s === 'Environmental Science'" color="#45ab1d" dark) {{ s }}
                          v-chip.mb-2.font-weight-light.mr-2(small, label v-if="s === 'Marine Science'" color="#002d59" dark) {{ s }}
                          v-chip.mb-2.font-weight-light.mr-2(small, label v-if="s === 'Geological Science'" color="#592100" dark) {{ s }}
                          v-chip.mb-2.font-weight-light.mr-2(small, label v-if="s === 'General Science'" color="#c38452" dark) {{ s }}
                          v-chip.mb-2.font-weight-light.mr-2(small, label v-if="s === 'Forensic Science'" color="#a1785c" dark) {{ s }}
                      h6.font-weight-regular
                        ais-highlight(:hit="item" attribute="title")
                        span.font-weight-light &nbsp ({{ item.year }})
                      h6.font-weight-regular.body-2.text--secondary
                        span.font-weight-light by &nbsp
                        span(
                        v-for="(fieldValue, index) in item['author']"
                        :key="`highlight-${fieldValue}-${index}`"
                        )
                          ais-highlight(
                          :hit="item"
                          :attribute="`author.${index}`")
                          template(v-if="index < item['author'].length - 1") , &nbsp

                      h6.text--secondary.font-weight-light.body-2 <span v-if="item.edition.length != 0"> {{ item.edition }} </span> <strong class="font-weight-black mx-1">•</strong> 
                        span
                          ais-highlight(:hit="item" attribute="publisher")
                      h6.text--secondary.font-weight-light.body-2 <span v-for="inst in item.institution.slice(0,item.institution.length-1)">{{ inst }}, </span> <span> {{ item.institution[item.institution.length-1] }} </span>
                      h6.font-weight-light.caption.text--secondary(
                        v-if="item.isbn.length != 0"
                      ) ISBN: 
                        span
                          ais-highlight(:hit="item" attribute="isbn")
                      h6.font-weight-light.caption.text--secondary(
                        v-if="item.doi.length != 0"
                      ) DOI: <span class="font-weight-regular"> {{ item.doi }} </span>
                      h6.font-weight-light.caption.text--secondary Views: <span class="font-weight-regular"> {{ item.views }} </span>
                  //- View Button
                  v-col(sm="3", cols="12").text-center
                    tooltipBtn(v-if="!bookmarks.includes(item.objectID)")
                      template(v-slot:content)
                        v-btn.mr-6(
                          rounded
                          small,
                          @click="addBookmark(item.objectID); index = item.objectID",
                          :loading="loading1 && item.objectID == index",
                        )
                          v-icon mdi-book-plus 
                      template(v-slot:tooltip-content) Add To Shelf
                    tooltipBtn(v-else)
                      template(v-slot:content)
                        v-btn.mr-6(
                          rounded
                          small,
                          to="../user/shelf",
                          color="teal"
                          dark
                        )
                          v-icon mdi-bookshelf
                      template(v-slot:tooltip-content) Go to the shelf page
                    tooltipBtn
                      template(v-slot:content)
                        a(:href="'/book/'+item.objectID" target="_blank")
                          v-btn(
                            color="primary",
                            small,
                            rounded
                          )
                            v-icon mdi-book-arrow-right
                      template(v-slot:tooltip-content) View Book

            div( v-if="items.length==0").text-center
              v-img(src="@/assets/noresults-min.png" max-width="300px",).mx-auto
              h3.font-weight-regular NO RESULTS FOUND
            
        ais-pagination
          div(
            slot-scope="{currentRefinement, nbPages,pages,isFirstPage,isLastPage,refine,createURL}"
          )
            .text-center.mt-5(v-if="pages.length >= 2")
              v-btn.mx-1(
                color="primary",
                x-small,
                fab,
                v-if="!isFirstPage",
                @click.prevent="refine(0); toTop()",
                outlined
              )
                v-icon mdi-chevron-double-left
              v-btn.mx-1(
                color="primary",
                x-small,
                fab,
                @click.prevent="refine(currentRefinement - 1); toTop()",
                outlined
              )
                v-icon mdi-menu-left
              v-btn.mx-1(
                color="primary",
                x-small,
                fab,
                v-for="page in pages.slice(0,4)",
                :key="page",
                :outlined="page != currentRefinement",
                @click.prevent="refine(page); toTop()"
              ) {{ page + 1 }}
              v-btn.mx-1(
                color="primary",
                x-small,
                fab,
                v-if="!isLastPage",
                @click.prevent="refine(currentRefinement + 1); toTop()",
                outlined
              )
                v-icon mdi-menu-right
              v-btn.mx-1(
                color="primary",
                x-small,
                fab,
                v-if="!isLastPage",
                @click.prevent="refine(nbPages); toTop()",
                outlined
              )
                v-icon mdi-chevron-double-right
</template>

<script>
import "firebase/functions";
import { history } from "instantsearch.js/es/lib/routers";
import { simple } from "instantsearch.js/es/lib/stateMappings";
import tooltipBtn from "../components/TooltipBtn.vue";
import bookCover from "../components/BookCover.vue";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: "Y1ojbcNINEhgaajOS6V6XQZAlKmDXCcE", // Be sure to use an API key that only allows search operations
    nodes: [
      {
        host: "ehubstorage.cslib.upd.edu.ph",
        port: "443",
        protocol: "https",
      },
    ],
    cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
  },
  additionalSearchParameters: {
    queryBy: "title, author, publisher, isbn",
    sort_by: "_text_match:desc, views:desc",
  },
});
const searchClient = typesenseInstantsearchAdapter.searchClient;

export default {
  components: { tooltipBtn, bookCover },
  data() {
    return {
      searchClient,
      hitsPerPage: 10,
      search: "",
      timeout: 3000,
      caution: false,
      drawer: false,
      page: 1,
      filter: false,
      routing: {
        router: history(),
        stateMapping: simple(),
      },
      loading: false,
      userId: "",
      focused: false,
      year: new Date().getFullYear().toString(),
      month: (new Date().getMonth() + 1).toString(),
    };
  },
  computed: {
    bookmarks() {
      return this.$store.state.userInfo.bookmarks;
    },
    loading1: {
      get() {
        return this.$store.state.loading;
      },
      set(payload) {
        this.$state.commit("updateLoading", payload);
      },
    },
  },

  methods: {
    async addBookmark(bookId) {
      if (this.$store.state.userInfo.bookmarks.length < 50) {
        this.$store.dispatch("addToShelf", bookId);
      } else {
        this.caution = true;
      }
    },

    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style>
.ais-Highlight-highlighted {
  background: #b8e2f2;
}

.v-list-item__content {
  text-transform: capitalize !important;
}
.ais-RangeInput-input {
  width: 60px;
  background: #e0e0e0;
  padding: 4px 4px 4px 4px;
  border-radius: 7px;
  font-size: 13px;
}

ul.ais-CurrentRefinements-list {
  list-style-type: none;
  display: inline;
}

.ais-CurrentRefinements-list li {
  list-style-type: none;
  display: inline;
}

ul {
  list-style-type: none;
  display: inline;
}

ul li {
  display: inline;
}
</style>
